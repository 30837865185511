import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PublicClientApplication, SilentRequest } from "@azure/msal-browser";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Projects from "./pages/projects/Projects";
import ProjectDetail from "./pages/projects/ProjectDetail";
import UploadChatData from "./pages/chatData/ChatData";
import Signin from "./components/Auth/Signin";

import { getUser } from "./api";
import { loginRequest, msalConfig } from "./util/authConfig";


initializeIcons();

const config = msalConfig(
    import.meta.env.VITE_AZURE_AUTH_CLIENT_ID,
    import.meta.env.VITE_AZURE_AUTH_TENANT_ID,
    import.meta.env.VITE_REDIRECT_URI,
);

const pca = new PublicClientApplication(config);

const FeatureDisabled = () => {
    return (
        <div style={{ padding: 20, fontSize: 18 }}>
            This feature has been disabled by your administrator.
        </div>
    )
}

export default function App() {
    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const tokenRequest: SilentRequest = { account: accounts[0], ...loginRequest }
    const [user, setUser] = useState<any>({});

    const getUserUtil = async () => {
        const { idToken } = await instance.acquireTokenSilent(tokenRequest);
        const user = await getUser(idToken);
        setUser(user);
    }

    useEffect(() => {
        getUserUtil();
    }, [isAuthenticated]);

    return (isAuthenticated ?
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={user && user.chat_enabled ? <Chat /> : <FeatureDisabled />} />
                    <Route path="/phase-1-edr-reports" element={user && user.report_enabled ? <Projects /> : <FeatureDisabled />} />
                    <Route path="/phase-1-edr-reports/:id" element={user && user.report_enabled ? <ProjectDetail /> : <FeatureDisabled />} />
                    <Route path="/upload-chat-data" element={user && user.chat_upload_enabled ? <UploadChatData /> : <FeatureDisabled />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
        :
        <Signin />
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
        <MsalProvider instance={pca}>
            <FluentProvider theme={webLightTheme} style={{ height: "100%" }}>
                <App />
            </FluentProvider>
        </MsalProvider>
    // </React.StrictMode>
);
