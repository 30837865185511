import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Divider,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Field,
    Input,
} from "@fluentui/react-components";
import { List, ListItem } from "@fluentui/react-list-preview";
import { useMsal } from "@azure/msal-react";
import styles from "./Projects.module.css";

import { loginRequest } from "../../util/authConfig";
import { createProject, getProjects } from "../../api";
import { SilentRequest } from "@azure/msal-browser";

const Projects = () => {
    const { accounts, instance } = useMsal();
    const tokenRequest: SilentRequest = { account: accounts[0], ...loginRequest }
    const navigate = useNavigate();
    const [projects, setProjects] = useState<any>([]);
    const [createOpen, setCreateOpen] = useState<any>(false);
    const [projectName, setProjectName] = useState<string>("");

    useEffect(() => {
        const fetchProjects = async () => {
            const { idToken } = await instance.acquireTokenSilent(tokenRequest);
            const projectRes: any = await getProjects(idToken);
            if (projectRes) setProjects(projectRes["projects"]);
        };
        fetchProjects();
    }, [createOpen]);

    const handleCreateProject = async () => {
        const { idToken } = await instance.acquireTokenSilent(tokenRequest);
        const project = await createProject(idToken, { name: projectName });
        setCreateOpen(false);
        setProjectName("");
        if (!project) {
            alert("Some error occurred creating project")
        }
    };

    return (
        <div className={styles.projectsContainer}>
            <div className={styles.buttonContainer}>
                <h2>Phase 1 EDR Reports</h2>
                <Dialog open={createOpen} onOpenChange={(event, data) => setCreateOpen(data.open)}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary">Create</Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody>
                        <DialogTitle>Create Report</DialogTitle>
                        <DialogContent>
                            <Field
                                required
                                label="Report name"
                                size="large"
                            >
                                <Input value={projectName} onChange={e => setProjectName(e.target.value)} />
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                            </DialogTrigger>
                            <Button appearance="primary" onClick={handleCreateProject}>Create</Button>
                        </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </div>
            <Divider style={{marginTop: 20, marginBottom: 20}} />
            <List navigationMode="items" className={styles.list}>
                {projects && projects.map((p: any) => <ListItem key={p.id} value={p.name} onAction={() => navigate(`/phase-1-edr-reports/${p.id}`)}>
                    <div className={styles.listItem}>{p.name}</div>
                </ListItem>)}
            </List>
        </div>
    )
};

export default Projects;
