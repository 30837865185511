import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import MsLogin from "../../assets/ms-login.svg";
import FlipType from "../../assets/fliptype.png";

import styles from "./Signin.module.css";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    return (
        <div className={styles.signincontainer}>
            <div className={styles.signincard}>
                <div className={styles.cardheader}>
                    <img src={FlipType} alt="FlipType Logo" />
                </div>
                <div className={styles.logincontainer}>
                    <img src={MsLogin} className={styles.loginimage} alt="Login with Microsoft" role="button" onClick={() => handleLogin()} />
                </div>
            </div>
        </div>
    )
}

export default SignInButton;
