import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import styles from "./Layout.module.css";
import FlipType from "../../assets/fliptype-small.png";
import { Stack } from "@fluentui/react";
import { Tooltip } from '@fluentui/react-components';
import {
    AppItem,
    Hamburger,
    NavDrawer,
    NavDrawerBody,
    NavDrawerHeader,
    NavItem,
} from "@fluentui/react-nav-preview";
import { useMsal } from "@azure/msal-react";


const Layout = () => {
    const location = useLocation();
    const pathname = "/" + location.pathname.split("/")[1];
    const { instance } = useMsal();

    const locationMap: any = {
        "/": "1",
        "/phase-1-edr-reports": "2",
        "/upload-chat-data": "3",
    };

    const renderHamburgerWithToolTip = () => {
        return (
        <Tooltip content="Navigation" relationship="label">
            <Hamburger />
        </Tooltip>
        );
    };

    return (
        <div className={styles.root}>
            <NavDrawer
                selectedValue={locationMap[pathname]}
                open={true}
                type="inline"
            >
                <NavDrawerHeader>{renderHamburgerWithToolTip()}</NavDrawerHeader>

                <NavDrawerBody>
                <AppItem>
                    FlipType AI
                </AppItem>
                <NavItem href="/#/" value="1">
                    Chat with your data
                </NavItem>
                <NavItem href="/#/phase-1-edr-reports" value="2">
                    Phase 1 EDR Reports
                </NavItem>
                <NavItem href="/#/upload-chat-data" value="3">
                    Upload chat data
                </NavItem>
                <AppItem onClick={() => instance.logoutRedirect()}>
                    Logout
                </AppItem>
                </NavDrawerBody>
            </NavDrawer>
            <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer} >
                        <Stack horizontal verticalAlign="center">
                            <img
                                src={FlipType}
                                className={styles.headerIcon}
                                aria-hidden="true"
                                alt="FlipType AI logo"
                            />
                            <Link to="/" className={styles.headerTitleContainer}>
                                <h3 className={styles.headerTitle}>FlipType AI</h3>
                            </Link>
                        </Stack>
                    </div>
                </header>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
