import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Divider,
} from "@fluentui/react-components";
import { List, ListItem } from "@fluentui/react-list-preview";
import { useMsal } from "@azure/msal-react";
import { useFilePicker } from 'use-file-picker';
import styles from "./ChatData.module.css";

import { loginRequest } from "../../util/authConfig";
import { uploadChatDataFile, getChatDataFiles } from "../../api";
import { SilentRequest } from "@azure/msal-browser";
import { BlockBlobClient } from "@azure/storage-blob";

const UploadChatData = () => {
    const { accounts, instance } = useMsal();
    const tokenRequest: SilentRequest = { account: accounts[0], ...loginRequest }
    const [uploadData, setUploadData] = useState<any>({});
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            prepareUploadFile(e.target.files[0]);
        }
    };

    const prepareUploadFile = async (file: any) => {
        setUploadInProgress(true);
        try {
            if (!file) return;
            const { idToken } = await instance.acquireTokenSilent(tokenRequest);
            let data = new FormData();
            data.append("file", file);
            data.append("container", "documents");
            const uploadData = await uploadChatDataFile(idToken, data);
            if (!uploadData) return;
        } catch (e) {
            alert("Error occurred while uploading file. Please try again !!")
        } finally {
            setUploadInProgress(false);
            fetchFiles();
        }

        // setUploadData({
        //     file,
        //     sasToken: uploadData.sas_token
        // });

        // handleUploadFile(file, uploadData.sas_token);
    }

    const handleUploadFile = async(file: any, sasToken: string) => {
        // Initialize blob service client
        setUploadInProgress(true);
        try {
            const blockBlobClient = new BlockBlobClient(sasToken);
            const options: any = { metadata: { title: file.name } }
            const uploadResponse = await blockBlobClient.upload(file.content, file.content.length, options)
            console.log(uploadResponse);
        } catch (e) {
            alert("Error occurred while uploading file. Please try again !!")
        } finally {
            setUploadData({});
            setUploadInProgress(false);
        }
    }

    const fetchFiles = async () => {
        const { idToken } = await instance.acquireTokenSilent(tokenRequest);
        const files: any = await getChatDataFiles(idToken);
        if (files) setFiles(files["files"])
    }

    useEffect(() => {
        fetchFiles();
    }, []);

    return (
        <div className={styles.projectsContainer}>
            <div className={styles.buttonContainer}>
                <h2>Upload Chat Data</h2>
                <div>
                    <label htmlFor="chatfile" className={styles.fileupload}>
                        { uploadInProgress ? "Uploading..." : "Upload File" }
                    </label>
                    <input style={{ display: "none" }} id="chatfile" type="file" onChange={handleFileChange} />
                </div>
            </div>
            <Divider style={{marginTop: 20, marginBottom: 20}} />
            <List navigationMode="items" className={styles.list}>
                {files && files.map((f: any) => <ListItem key={f.fullpath} value={f.filename} onAction={() => {}}>
                    <div className={styles.listItem}>{f.filename.split("/")[1]}</div>
                </ListItem>)}
            </List>
        </div>
    )
};

export default UploadChatData;
