import { ConversationRequest } from "./models";


export async function callConversationApi(token: string, options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/api/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            messages: options.messages,
            conversation_id: options.id
        }),
        signal: abortSignal
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData.error));
    }

    return response;
}

export async function getAssistantTypeApi(token: string) {
    try {
        const response = await fetch("/api/assistanttype", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const config = await response.json(); // Parse JSON response
      return config;
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      return null; // Return null or some default value in case of error
    }
  }

export async function getProjects(token: string) {
    try {
        const response = await fetch("/api/project", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const projects = await response.json(); // Parse JSON response
      return projects;
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      return null; // Return null or some default value in case of error
    }
}

export async function getProject(token: string, projectId: string) {
    try {
        const response = await fetch(`/api/project/${projectId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const project = await response.json(); // Parse JSON response
      return project;
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      return null; // Return null or some default value in case of error
    }
}

export async function createProject(token: string, project: any) {
    try {
        const response = await fetch("/api/project", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(project),
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const projectRes = await response.json(); // Parse JSON response
      return projectRes;
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      return null; // Return null or some default value in case of error
    }
}

export async function getProjectFiles(token: string, projectId: string) {
    try {
        const response = await fetch(`/api/project/${projectId}/files`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const files = await response.json(); // Parse JSON response
      return files;
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      return null; // Return null or some default value in case of error
    }
}

export async function getProjectResult(token: string, projectId: string) {
  try {
      const response = await fetch(`/api/project/${projectId}/result`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
          },
      });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const files = await response.json(); // Parse JSON response
    return files;
  } catch (error) {
    console.error('Failed to fetch configuration:', error);
    return null; // Return null or some default value in case of error
  }
}

export async function uploadProjectFile(token: string, projectId: string, data: FormData) {
    try {
        const response = await fetch(`/api/project/${projectId}/upload`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: data,
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const uploadRes = await response.json(); // Parse JSON response
      return uploadRes;
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      return null; // Return null or some default value in case of error
    }
}

export async function getChatDataFiles(token: string) {
  try {
      const response = await fetch(`/api/chat/files`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
          },
      });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const files = await response.json(); // Parse JSON response
    return files;
  } catch (error) {
    console.error('Failed to fetch configuration:', error);
    return null; // Return null or some default value in case of error
  }
}

export async function uploadChatDataFile(token: string, data: FormData) {
  try {
      const response = await fetch(`/api/chat/upload`, {
          method: "POST",
          headers: {
              "Authorization": `Bearer ${token}`
          },
          body: data,
      });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const uploadRes = await response.json(); // Parse JSON response
    return uploadRes;
  } catch (error) {
    console.error('Failed to fetch configuration:', error);
    return null; // Return null or some default value in case of error
  }
}

export async function getUser(token: string) {
  try {
      const response = await fetch(`/api/me`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
          },
      });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const user = await response.json(); // Parse JSON response
    return user;
  } catch (error) {
    console.error('Failed to fetch configuration:', error);
    return null; // Return null or some default value in case of error
  }
}
