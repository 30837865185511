import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useFilePicker } from 'use-file-picker';
import {
    Button,
    Divider,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
} from "@fluentui/react-components";
import { List, ListItem } from "@fluentui/react-list-preview";
import { useMsal } from "@azure/msal-react";
import { BlockBlobClient } from "@azure/storage-blob";
import styles from "./Projects.module.css";

import { getProject, getProjectFiles, uploadProjectFile, getProjectResult } from "../../api";
import { loginRequest } from "../../util/authConfig";
import { SilentRequest } from "@azure/msal-browser";

const ProjectDetail = () => {
    const { id } = useParams();
    const { accounts, instance } = useMsal();
    const tokenRequest: SilentRequest = { account: accounts[0], ...loginRequest }
    const [project, setProject] = useState<any>({});
    const [overwriteOpen, setOverwriteOpen] = useState<any>(false);
    const [uploadData, setUploadData] = useState<any>({});
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);
    const [result, setResult] = useState<any>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            prepareUploadFile(e.target.files[0]);
        }
    };

    const prepareUploadFile = async (file: any) => {
        setUploadInProgress(true);
        try {
            if (!file) return;
            const { idToken } = await instance.acquireTokenSilent(tokenRequest);
            let data = new FormData();
            data.append("file", file);
            data.append("container", "projectfiles");
            const uploadData = await uploadProjectFile(idToken, id ?? "", data);
            if (!uploadData) return;
        } catch (e) {
            alert("Error occurred while uploading file. Please try again !!")
        } finally {
            setUploadInProgress(false);
            fetchFiles();
        }

        // setUploadData({
        //     file,
        //     sasToken: uploadData.sas_token
        // });

        // handleUploadFile(file, uploadData.sas_token);
    }

    const handleUploadFile = async(file: any, sasToken: string) => {
        // Initialize blob service client
        setOverwriteOpen(false);
        setUploadInProgress(true);
        try {
            const blockBlobClient = new BlockBlobClient(sasToken);
            const uploadResponse = await blockBlobClient.upload(file.content, file.content.length)
            console.log(uploadResponse);
        } catch (e) {
            alert("Error occurred while uploading file. Please try again !!")
        } finally {
            setUploadData({});
            setUploadInProgress(false);
            fetchFiles();
        }
    }

    const fetchProject = async () => {
        const { idToken } = await instance.acquireTokenSilent(tokenRequest);
        const projectRes: any = await getProject(idToken, id ?? "");
        if (projectRes) setProject(projectRes);
    };

    const fetchFiles = async () => {
        const { idToken } = await instance.acquireTokenSilent(tokenRequest);
        const files: any = await getProjectFiles(idToken, id ?? "");
        if (files) setFiles(files["files"])
    }

    const fetchResult = async () => {
        const { idToken } = await instance.acquireTokenSilent(tokenRequest);
        const files: any = await getProjectResult(idToken, id ?? "");
        if (files) setResult(files["files"])
    }

    useEffect(() => {
        fetchProject();
        fetchFiles();
        fetchResult();
    }, []);

    return (
        <div className={styles.projectsContainer}>
            <div className={styles.buttonContainer}>
                <h2>{project.name}</h2>
                <div>
                    <label htmlFor="reportfile" className={styles.fileupload}>
                        { uploadInProgress ? "Uploading..." : "Upload File" }
                    </label>
                    <input style={{ display: "none" }} id="reportfile" type="file" onChange={handleFileChange} />
                </div>
                <Dialog open={overwriteOpen} onOpenChange={(event, data) => setOverwriteOpen(data.open)}>
                    <DialogSurface>
                        <DialogBody>
                        <DialogTitle>File Exists</DialogTitle>
                        <DialogContent>
                            <p>File already exists. Do you want to overwrite the existing file?</p>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                            </DialogTrigger>
                            <Button appearance="primary" onClick={() => handleUploadFile(uploadData.file, uploadData.sasToken)}>Overwrite</Button>
                        </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </div>
            <Divider style={{marginTop: 20, marginBottom: 20}} />
            <List navigationMode="items" className={styles.list}>
                {files && files.map((f: any) => <ListItem key={f.fullpath} value={f.filename} onAction={() => {}}>
                    <div className={styles.listItem}>{f.filename.split("/")[1]}</div>
                </ListItem>)}
            </List>
            <Divider style={{marginTop: 20, marginBottom: 20}} />
            <h3>Result files</h3>
            <List navigationMode="items" className={styles.list}>
                {result && result.map((f: any) => <ListItem key={f.fullpath} value={f.filename} onAction={() => {}}>
                    <div className={styles.listItem}>
                        {f.filename.split("/")[1]}
                        <a href={f.fullpath}>Download</a>
                    </div>
                </ListItem>)}
            </List>
        </div>
    );
}

export default ProjectDetail;
